import axios from 'axios'

const simejuvApi = axios.create({
    //baseURL: 'http://localhost/ubApi/index.php',
    baseURL: 'https://cc.cuidandoelcamino.org/ubApi/index.php',
    //baseURL: 'https://cc.rmsh.club/ubApi/index.php',

    headers: {
        "Content-type": "application/json"
    }
})

export default simejuvApi