import { ref } from 'vue'
//export const url = ref( 'http://localhost' )
//export const ubApi = ref( 'http://localhost/ubApi' )
//export const icons = ref( 'http://localhost/icons' ) 

export const url = ref( 'https://cc.cuidandoelcamino.org' )
export const ubApi = ref( 'https://cc.cuidandoelcamino.org/ubApi' )
export const icons = ref( 'https://cc.cuidandoelcamino.org/icons' ) 

//export const url = ref( 'https://cc.rmsh.club' )
//export const ubApi = ref( 'https://cc.rmsh.club/ubApi' )
//export const icons = ref( 'https://cc.rmsh.club/icons' ) 
